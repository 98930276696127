import { jsx as _jsx } from "react/jsx-runtime";
import { keyBy } from 'lodash/fp';
import * as uriHelper from '../../helpers/uri';
const BASE_URL = '/images/google-product-icons';
const MATCHERS = [
    {
        type: 'googleDrive',
        hostname: 'drive.google.com',
        re: /https?:\/\/drive\.google\.com/,
        toText: uriHelper.stripScheme,
        iconName: 'drive',
    },
    {
        type: 'googleDocs',
        hostname: 'docs.google.com',
        re: /https?:\/\/docs\.google\.com\/document/,
        toText: uriHelper.stripScheme,
        iconName: 'docs',
    },
    {
        type: 'googleSpreadsheet',
        hostname: 'docs.google.com',
        re: /https?:\/\/docs\.google\.com\/spreadsheets?/,
        toText: uriHelper.stripScheme,
        iconName: 'spreadsheet',
    },
    {
        type: 'googlePresentation',
        hostname: 'docs.google.com',
        re: /https?:\/\/docs\.google\.com\/presentations?/,
        toText: uriHelper.stripScheme,
        iconName: 'presentation',
    },
    {
        type: 'gmail',
        hostname: 'mail.google.com',
        re: /https?:\/\/mail\.google\.com\/mail\/*/,
        toText: uriHelper.stripScheme,
        iconName: 'gmail',
    },
];
const MATCHER_BY_TYPE = keyBy('type', MATCHERS);
export function match(link) {
    for (let i = 0; i < MATCHERS.length; i++) {
        const matcher = MATCHERS[i];
        if (uriHelper.matchesHostname(link, matcher.hostname) && matcher.re.test(link)) {
            return {
                type: matcher.type,
                text: matcher.toText(link),
            };
        }
    }
    return null;
}
export function getIcon({ type }) {
    const { iconName } = MATCHER_BY_TYPE[type];
    const src = `${BASE_URL}/${iconName}-16.png`;
    const alt = `Google ${iconName} icon`;
    return _jsx("img", { src: src, alt: alt });
}
export function render(props) {
    return props.text || props.link;
}
