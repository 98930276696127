export function extractFileName(uri) {
    return decode(getLastPart(uri));
}
export function decode(uri) {
    return window.decodeURI(uri);
}
export function getLastPart(uri) {
    var _a, _b;
    return (_b = (_a = uri === null || uri === void 0 ? void 0 : uri.match(/.+\/(.*)$/)) === null || _a === void 0 ? void 0 : _a[1]) !== null && _b !== void 0 ? _b : '';
}
export function stripScheme(uri) {
    var _a, _b;
    return (_b = (_a = uri === null || uri === void 0 ? void 0 : uri.match('https?://(.*)$')) === null || _a === void 0 ? void 0 : _a[1]) !== null && _b !== void 0 ? _b : '';
}
export function split(uri) {
    const m = uri === null || uri === void 0 ? void 0 : uri.match('(https?://)?(.*?)$');
    if (!m) {
        return [];
    }
    const scheme = m[1];
    const rest = m[2];
    const parts = rest.split('/');
    return scheme ? [scheme].concat(parts) : parts;
}
export function matchesHostname(uri, hostname) {
    try {
        return new URL(uri).hostname === hostname;
    }
    catch (_a) {
        return false;
    }
}
