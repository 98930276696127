import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { IconEdit } from 'featherico';
import { every, filter, includes, map, reduce } from 'lodash';
import PropTypes from 'prop-types';
import { pure } from '../../../hoc/recompose';
import Button from '../../Button';
import { DeprecatedButtonContainer } from '../../ButtonContainer';
import Crossfade from '../../Crossfade';
import IconValidation from '../../IconValidation';
import { Shaking, useShaker } from '../../Shake';
import { ScrollStep, WizardScroller } from '../../Wizard/Scroller';
import styles from './style.m.less';
function CycleCreateContent(props) {
    const { actions, controls, content, currentStep, editedSteps, model, validationData, mode } = props;
    const editableContent = filter(content, item => !!item.components.edit);
    const editedStates = getEditedStates(editableContent, editedSteps);
    const nextButtons = getNextButtons(editableContent, editedSteps, model, actions, validationData, mode);
    const isValid = getIsValid(content, model, validationData, mode);
    const controlProps = {
        isValid: isValid && every(editedStates),
    };
    return (_jsx(WizardScroller, { currentStepKey: currentStep, children: _jsxs("section", { className: "cycle-create-content cards_section", children: [map(content, (item, key) => {
                    const { id, canEdit } = item;
                    const editMode = currentStep === id;
                    const nextButton = nextButtons[id];
                    const wasEdited = editedStates[id];
                    const itemProps = {
                        model,
                        item,
                        actions,
                        nextButton,
                        wasEdited,
                        editMode,
                        validationData,
                        currentStep,
                        canEdit: (canEdit || (() => true))(),
                        mode,
                    };
                    return _jsx(ItemContainer, Object.assign({}, itemProps), key);
                }), _jsx(controls.component, Object.assign({}, controlProps))] }) }));
}
const ItemContainer = props => {
    const { editMode, wasEdited, item, currentStep, actions } = props;
    const { id, className } = item;
    const step = { key: id };
    const isUntouched = getIsUntouched(wasEdited, editMode);
    const isEmpty = !id;
    const containerClasses = classNames('cycle-create-content-item-container', {
        'cycle-create-content-item-untouched': isUntouched,
    });
    const mainClasses = classNames('cycle-create-content-item-main', {
        'cycle-create-content-item-main-untouched': isUntouched,
        'cycle-create-content-item-main-inactive': !isUntouched && !editMode,
        'cycle-create-content-item-main-active': !isUntouched && editMode,
        'cycle-create-content-item-main-empty': isEmpty,
        card: !isUntouched,
    }, className);
    const onEnterEdit = () => actions.enterEditMode(id);
    const shake = useShaker(currentStep);
    const body = () => {
        if (isUntouched) {
            return (_jsx(ScrollStep, { step: step, children: _jsx(ItemHeadline, Object.assign({}, props, { onClick: shake })) }, "body-untouched"));
        }
        return editMode ? (_jsx(ScrollStep, { step: step, children: _jsx(ContentEditItem, Object.assign({}, props)) }, "body-edit")) : (_jsx(ScrollStep, { step: step, children: _jsx(ContentPreviewItem, Object.assign({}, props, { onEnterEdit: onEnterEdit })) }, "body-preview"));
    };
    return (_jsx("div", { className: containerClasses, children: _jsx("div", { className: mainClasses, children: _jsx(Crossfade, { children: body() }) }) }));
};
function ContainerIcon({ wasEdited, isValid, isEditMode }) {
    if (!wasEdited || (!isEditMode && isValid)) {
        return null;
    }
    return (_jsx("span", { className: "cycle-create-content-item-title-icon", children: _jsx(Crossfade, { children: isEditMode ? _jsx(IconEdit, {}, "edit-icon") : _jsx(IconValidation, { isValid: isValid }, "validation-icon") }) }));
}
const ItemTitle = pure(function ItemTitle({ children, active, isPreview, options }) {
    const props = {
        children,
        className: classNames({
            active: active,
        }, 'card-section-heading'),
    };
    return (_jsxs("header", { className: isPreview ? 'cycle-create-content-item-title' : styles.sectionHeader, children: [_jsx("h1", Object.assign({}, props)), options] }));
});
function ItemHeadline({ model, wasEdited, editMode, item, isUntouched, canEdit, onClick, validationData, mode }) {
    const { imperativeLabel, label } = item;
    const getLabel = () => {
        const text = editMode || isUntouched || !checkValidity(item, model, validationData, mode) ? imperativeLabel : label;
        if (onClick && canEdit) {
            return (_jsx(Button, { kind: "PLAIN", className: "card-header-heading-link", onClick: onClick, children: text }));
        }
        else {
            return text;
        }
    };
    const isPreview = wasEdited && !editMode;
    return (_jsxs(ItemTitle, { isPreview: isPreview, options: editMode && item.components.editOptions && _jsx(item.components.editOptions, {}), children: [_jsx(ContainerIcon, { wasEdited: wasEdited, isValid: checkValidity(item, model, validationData, mode), isEditMode: editMode }), editMode ? ' ' : null, getLabel()] }));
}
function ContentEditItem(props) {
    const { item, nextButton, wasEdited } = props;
    const { onClick } = nextButton;
    const { components, id, stickyButtons } = item;
    return (_jsxs("div", { children: [_jsx(ItemHeadline, Object.assign({}, props)), _jsxs("div", { className: "cycle-create-content-item-edit", children: [_jsx(components.edit, { wasEdited: wasEdited }), _jsx("div", { className: classNames({ [styles.stickyButton]: stickyButtons }), children: _jsx(Shaking, { shakeKey: id, children: _jsxs(DeprecatedButtonContainer, { children: [components.skip ? _jsx(components.skip, { onSkip: onClick }) : null, components.next ? _jsx(components.next, Object.assign({}, nextButton)) : _jsx(NextButton, Object.assign({}, nextButton))] }) }) })] })] }));
}
function ContentPreviewItem(props) {
    const { item, canEdit } = props;
    const { components } = item;
    return (_jsxs("div", { className: "cycle-create-content-item-preview", children: [_jsx("div", { className: "cycle-create-content-item-preview-title", children: _jsx(ItemHeadline, Object.assign({}, props, { onClick: props.onEnterEdit })) }), _jsx("div", { className: "cycle-create-content-item-preview-content", children: _jsx(components.preview, {}) }), _jsxs(Button, { className: "cycle-create-content-item-preview-edit-button", onClick: props.onEnterEdit, disabled: !canEdit, children: [_jsx(IconEdit, {}), " Edit"] })] }));
}
function NextButton(_a) {
    var { label } = _a, other = __rest(_a, ["label"]);
    return (_jsx(Button, Object.assign({ kind: "PRIMARY" }, other, { children: label })));
}
function getNextButtons(content, editedSteps, model, { next, leaveEditMode }, validationData, mode) {
    const allEdited = wereAllStepsEdited(content, editedSteps);
    return reduce(content, (mem, item) => {
        const itemEdited = wasItemEdited(item, editedSteps);
        const label = itemEdited ? 'Done' : 'Next';
        const onClick = allEdited ? leaveEditMode : next;
        mem[item.id] = {
            label,
            onClick,
            disabled: !checkNextButtonValidity(item, model, validationData, mode),
        };
        return mem;
    }, {});
}
function wereAllStepsEdited(content, editedSteps) {
    return every(content, contentItem => wasItemEdited(contentItem, editedSteps));
}
function wasItemEdited(item, editedSteps) {
    return editedSteps.indexOf(item.id) !== -1;
}
function checkNextButtonValidity(item, model, validationData, mode) {
    const { isNextEnabled } = item.components.edit;
    if (isNextEnabled) {
        return isNextEnabled(model);
    }
    else {
        return checkValidity(item, model, validationData, mode);
    }
}
function checkValidity(item, model, validationData, mode) {
    return (item.components.edit.isValid || (() => true))(model, validationData, mode);
}
function checkValidityWithTimeline(item, model, validationData, mode) {
    const editComponentValidFn = (item.components.edit && item.components.edit.isValid) || (() => true);
    const timelineComponentValidFn = (item.components.timeline && item.components.timeline.isValid) || (() => true);
    return editComponentValidFn(model, validationData, mode) && timelineComponentValidFn(model);
}
function getIsUntouched(wasEdited, editMode) {
    return !wasEdited && !editMode;
}
function getEditedStates(content, editedSteps) {
    return reduce(content, (mem, item) => {
        mem[item.id] = includes(editedSteps, item.id);
        return mem;
    }, {});
}
function getIsValid(content, model, validationData, mode) {
    return every(content, item => checkValidityWithTimeline(item, model, validationData, mode));
}
CycleCreateContent.propTypes = {
    controls: PropTypes.shape({
        component: PropTypes.elementType,
    }),
    content: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        components: PropTypes.shape({
            preview: PropTypes.elementType,
            edit: PropTypes.elementType,
        }),
    })).isRequired,
    currentStep: PropTypes.string,
    actions: PropTypes.shape({
        next: PropTypes.func,
        enterEditMode: PropTypes.func,
        leaveEditMode: PropTypes.func,
    }),
};
export { CycleCreateContent };
