import { jsx as _jsx } from "react/jsx-runtime";
import { IconDropbox } from 'featherico';
import * as uriHelper from '../../helpers/uri';
export function match(link) {
    if (!uriHelper.matchesHostname(link, 'dl.dropboxusercontent.com')) {
        return null;
    }
    return {
        text: uriHelper.decode(uriHelper.getLastPart(link)),
    };
}
export function getIcon() {
    return _jsx(IconDropbox, {});
}
export function render(props) {
    return props.text || props.link;
}
